import React from "react"
import PrivateRoute from "../../../../../components/auth/PrivateRoute/loadable"
import ProfileRoute from "../../../../../components/auth/ProfileRoute"
import ProposalBuy from "../../../../../components/Proposal/ProposalBuy"

const ProposalsBuyPage = props => (
  <PrivateRoute url={props.location.href}>
    <ProfileRoute>
      <ProposalBuy url={props.location.href} />
    </ProfileRoute>
  </PrivateRoute>
)

export default ProposalsBuyPage
